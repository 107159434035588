import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/introduction",
    name: "introduction",
    component: () => import("../views/introduction.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () => import("../views/news.vue"),
  },
  {
    path: "/newsdetails",
    name: "newsdetails",
    component: () => import("../views/newsdetails.vue"),
  },
  {
    path: "/projectdetails",
    name: "projectdetails",
    component: () => import("../views/projectdetails.vue"),
  },
  {
    path: "/picture",
    name: "picture",
    component: () => import("../views/picture.vue"),
  },
  {
    path: "/files",
    name: "files",
    component: () => import("../views/files.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("../views/search.vue"),
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {  
    if (savedPosition) {  
      return savedPosition;  
    } else {  
      return { x: 0, y: 0 };  
    }  
  }  
});

export default router;
