<template>
  <div id="app">
    <!-- 电脑端头部 -->
    <div class="pc_header">
      <div class="header_2">
        <div class="container_pad">
          <!-- <div class="row "> -->
          <div class="header_222">
            <a href="/"><img src="./images/logo.png" alt="魏都慈善总会" /></a>
            <div class="fr--- header_mid_right">
              <div class="header_mid_right_mid">
                <div class="header_mid_right_mid_left">
                  <div class="header_mid_right_mid_left_right">
                    <input
                      type="text"
                      v-model="search"
                      placeholder="请输入关键词查询"
                    />
                  </div>
                </div>
                <div class="header_mid_right_mid_right">
                  <button type="button" @click="searchnews">搜索</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="navPc">
      <div class="container_pad">
        <ul class="header_2_ul clearfix">
          <li><a href="/" class="active" rel="nofollow">首页</a></li>
          <li v-for="item in menulist" :key="item.menuId">
            <a
              @click="
                goto(
                  item.children.length > 0 ? item.children[0].path : item.path
                )
              "
              >{{ item.menuName }}
            </a>
            <ul class="er-ul" v-if="item.children.length > 0">
              <li v-for="citem in item.children" :key="citem.menuId">
                <a @click="goto(citem.path)">{{ citem.menuName }}</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <!-- 手机端导航 -->
    <div class="phonenav">
      <div class="container">
        <div class="clearfix">
          <div class="phone_logo fl">
            <a href="/" rel="nofollow"
              ><img src="./images/logo.png" alt="魏都慈善总会"
            /></a>
          </div>
          <div class="fr ac side-menu-trigger" @click="drawer = true">
            <span class="el-icon-menu" style="font-size: 22px"></span>
          </div>
        </div>
      </div>
    </div>
    <!-- 导航 -->

    <el-drawer title="菜单" :visible.sync="drawer" :direction="direction">
      <el-menu
        class="el-menu-vertical-demo"
        router
        menu-trigger="click"
        @select="handleSelect"
      >
        <el-menu-item class="el-submenu__title" index="/">
          <template slot="title">首页</template>
        </el-menu-item>
        <el-submenu
          :index="i.toString()"
          v-for="(item, i) in menulist"
          :key="item.menuId"
        >
          <template slot="title">{{ item.menuName }}</template>
          <el-menu-item
            :index="citem.path"
            v-for="(citem, cindex) in item.children"
            :key="citem.menuId"
            class="el-submenu__title"
          >
            {{ citem.menuName }}
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </el-drawer>
    <div class="phone-tHeader"></div>
    <router-view />
    <footer>
      <div class="t_footer wow fadeInUp t_footerbg">
        <div class="t_footertop">
          <div class="container-content">
            <div class="t_footle">
              <div class="t_dixin">
                <div class="t_diul">
                  <div class="t_footfirst">
                    <a @click="goto('introduction?id=505445442883653')"
                      >联系我们</a
                    >
                  </div>
                </div>
                <div class="t_diul">
                  <a :href="'tel:' + tel">
                    <div class="t_dili clearfix">
                      <div class="t_diwen fl">
                        <h3>{{ tel }}</h3>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="t_diul">
                  <div class="t_dili clearfix">
                    <div class="t_diwen fl">
                      <p>地址：{{ address }}</p>
                    </div>
                  </div>
                </div>
                <div class="t_diul">
                  <div class="t_dili clearfix">
                    <div class="t_diwen fl">
                      <p>邮箱：{{ email }}</p>
                    </div>
                  </div>
                </div>
                <div class="t_diul">
                  <div class="t_dili clearfix">
                    <div class="t_diwen fl">
                      <p>邮编：{{ postalcode }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="t_footEwm">
              <div class="t_footfirst">
                <a href="">关注我们</a>
              </div>
              <div><img src="./images/ewm1.jpg" title="" /></div>
            </div>
          </div>
        </div>
        <div class="t_footdown">
          <div class="container-content">
            <div class="t_didown clearfix">
              <div class="t_didownp">
                <p>
                  版权所有：{{ webname }}&nbsp;&nbsp;&nbsp;&nbsp;<a
                    :href="
                      'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=' +
                      webYwgab
                    "
                    target="_blank"
                    v-if="webYwgab"
                    ><img src="./images/beian.png" alt="" v-if="webYwgab" />{{
                      webYwgab
                    }}</a
                  >&nbsp;&nbsp;&nbsp;&nbsp;
                  <a href="https://beian.miit.gov.cn/" target="_blank">{{
                    webICP
                  }}</a
                  >&nbsp;&nbsp;&nbsp;&nbsp;技术支持：<a
                    href="http://www.bfrj.net/"
                    target="_blank"
                    >河南新兵锋软件科技有限公司</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      drawer: false,
      direction: "ttb",
      menulist: [],
      search: "",
      tel: "",
      address: "",
      email: "",
      postalcode: "",
      webname: "",
      webICP: "",
      webYwgab: "",
    };
  },
  mounted() {
    this.getMenuList();
    this.getConfig();
  },
  methods: {
    getMenuList() {
      this.$http.get("/api/WebMenu/list/1090").then((resJson) => {
        if (resJson.data.code == 200) {
          this.menulist = resJson.data.data;
        }
      });
    },
    getConfig() {
      this.$http.get("/api/WebConfig/list").then((resJson) => {
        if (resJson.data.code == 200) {
          var data = resJson.data.data.result;
          var webName = data.find((it) => it.configKey == "网站名称");
          var webKeywords = data.find((it) => it.configKey == "网站关键字");
          var webDescription = data.find((it) => it.configKey == "网站描述");
          var webICP = data.find((it) => it.configKey == "ICP备案");
          var webAddress = data.find((it) => it.configKey == "地址");
          var webPhone = data.find((it) => it.configKey == "手机号码");
          var webNumber = data.find((it) => it.configKey == "固定电话");
          var webFax = data.find((it) => it.configKey == "传真");
          var webEmail = data.find((it) => it.configKey == "邮箱");
          var webYwgab = data.find((it) => it.configKey == "豫公网安备");
          var webPostalCode = data.find((it) => it.configKey == "邮编");
          this.tel = webNumber.configValue;
          this.address = webAddress.configValue;
          this.email = webEmail.configValue;
          this.postalcode = webPostalCode.configValue;
          this.webname = webName.configValue;
          this.webICP = webICP.configValue;
          this.webYwgab = webYwgab.configValue;
          document.title = this.webname;
          const metaKeywords = document.querySelector('meta[name="keywords"]');
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          metaKeywords.setAttribute("content", webKeywords.configValue);
          metaDescription.setAttribute("content", webDescription.configValue);
        }
      });
    },
    goto(data) {
      // this.$router.push(data);
      this.$router.push(data).catch((error) => {
        if (error.name === "NavigationDuplicated") {
          // 已经处于目标位置，不需要做任何事情
        } else {
          // 其他错误，需要进一步处理
        }
      });
    },
    //搜索
    searchnews() {
      this.$router.push(
        { path: "/search", query: { key: this.search } },
        () => {},
        () => {}
      );
    },
    handleSelect(to, from) {
      this.drawer = false;
      // 执行页面跳转
      if (to.path !== from.path) {
        this.$router.push(to);
      }
    },
  },
};
</script>
<style>
.el-drawer {
  height: 100% !important; /* 覆盖默认的 height: 30%; */
}
.el-drawer__header {
  background: #eff2f6;
  position: relative;
  display: block;
  padding: 15px 25px;
  width: 100%;
  color: #fff;
  margin-right: 105px;
  line-height: 35px;
  color: #646060;
  font-size: 16px;
  margin-bottom: 1px;
}
.el-drawer__close-btn {
  float: right;
  font-size: 32px;
  color: #333;
}
.el-drawer__body {
  position: fixed;
  top: 65px;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
  background: #fff;
  list-style-type: none;
  border-top: 1px solid #cec7c7;
  z-index: 999;
}
.el-submenu__title {
  display: block;
  /* padding: 15px 25px; */
  color: #828282;
  font-size: 16px;
  font-weight: 400;
  transition: color 0.1s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.el-menu-item.is-active {
  color: #d50915;
}
</style>
