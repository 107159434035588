<template>
  <swiper class="swiper-wrapper" :options="swiperOptions">
    <swiper-slide
      v-for="(item, index) in imgData"
      :key="index"
      class="swiper-slide t_syk3li fl"
    >
      <a @click="goto('projectdetails?id=' + item.id)">
        <div class="t_syk3xin">
          <div class="t_syk3pic">
            <!-- <img class="t_tou" src="@/images/syk3_h.png" alt="" /> -->
            <el-image
              :src="ImageUrl + item.firstImg"
              style="border-radius: 10px 10px 0 0"
            >
              <div slot="error" class="image-slot">
                <img src="@/images/noImg.jpg" />
              </div>
            </el-image>
          </div>
          <div class="t_syk3wen">
            <div class="t_syk3title">
              {{ item.title }}
            </div>
            <div>救助时间：{{ item.rescuetime }}</div>
          </div>
        </div>
      </a>
    </swiper-slide>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination" slot="pagination"></div>

    <!-- 如果需要导航按钮 -->
    <!-- <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div> -->
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  props: {
    imgData: Array,
    default: () => [], // 提供一个默认空数组
  },
  watch: {
    imgData(newVal) {
      // 当 imgData 更新时执行某些操作
      //   this.images = newVal;
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      ImageUrl: this.$ImageUrl,
      swiperOptions: {
        // 设置响应式
        observer: true, // 修改swiper的可观察性项时，例如swiper的父元素被隐藏或显示
        observeParents: true, // 修改swiper的子元素时，例如swiper的子元素宽度变化
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        // 设置切换比率
        slidesPerView: "1", // 设置为auto可以根据宽度自动调整显示的slides数量
        spaceBetween: 10, // 设置slide之间的距离

        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 允许点击分页器的指示点进行切换
        },
        breakpoints: {
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
        },
        // 如果需要导航按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  methods: {
    goto(data) {
      this.$router.push(data);
    },
  },
};
</script>
<style>
.swiper-pagination .swiper-pagination-bullet-active {
  opacity: 1;
  background: #d50915;
}
</style>
