import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0ea4e362"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "@/css/index.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "@/css/swiper.min.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "@/css/swiper-news.css?vue&type=style&index=2&prod&lang=css&external"
import style3 from "./index.vue?vue&type=style&index=3&id=0ea4e362&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports