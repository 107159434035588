import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo:{} //用户登录状态
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
// 定义一个常量
// Vue.prototype.$ImageUrl = 'http://localhost:8889'; 
// axios.defaults.baseURL = 'http://localhost:8889';
Vue.prototype.$ImageUrl = 'http://api.wdcszh.cn'; 
axios.defaults.baseURL = 'http://api.wdcszh.cn';

axios.defaults.timeout = 10000; // 超时时间
// axios.defaults.headers = {}
Vue.prototype.$http = axios 

import Antd from 'ant-design-vue'
Vue.use(Antd)

Vue.config.productionTip = false



import "./css/bootstrap.css";
import "./css/animate.css";
import "./css/reset.css";
import "./css/font.css";
import "./css/iconfont.css";
import "./css/common.css";
		
// import "./css/animate.css";

Vue.use(ElementUI);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

