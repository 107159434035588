<template>
  <div id="main">
    <div class="banner w100">
      <swiper-component :imgData="bannerList"></swiper-component>
    </div>
    <!-- aboutus -->
    <div class="aboutus">
      <div class="container-content">
        <div class="title-box">
          <div class="title">
            <span>关于我们</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-12 col-xs-12 company-introduct">
            <div class="lft-title">
              <h3>
                <img
                  src="@/images/lftLogo.png"
                  alt=""
                />许昌市魏都区慈善联合总会概况
              </h3>
              <p>魏都慈善心 共建许昌城</p>
            </div>
            <div class="about-detail">
              {{ AboutUs }}
            </div>
            <div class="more">
              <a @click="goto('introduction?id=505443866734661')">查看更多</a>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-xs-12">
            <ul class="aboutList">
              <li>
                <a @click="goto('introduction?id=505443951173701')">
                  <img src="@/images/icon-constitution.png" alt="" />
                  <p>总会章程</p>
                </a>
              </li>
              <li>
                <a @click="goto('introduction?id=505445080768581')">
                  <img src="@/images/icon-history.png" alt="" />
                  <p>组织架构</p>
                </a>
              </li>
              <li>
                <a @click="goto('picture?cid=505793395253317')">
                  <img src="@/images/icon-imgs.png" alt="" />
                  <p>团队风采</p>
                </a>
              </li>
              <li>
                <a @click="goto('introduction?id=505445151363141')">
                  <img src="@/images/icon-constitution.png" alt="" />
                  <p>理事会成员</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- news -->
    <div class="news wow fadeInUp">
      <div class="container-content">
        <div class="title-box">
          <div class="title">
            <span>新闻资讯</span>
          </div>
        </div>
        <div class="class-list">
          <a
            v-for="(item, i) in classifyList"
            :key="item.id"
            @click="goto('news?cid=' + item.id)"
            :title="item.title"
            :class="{ on: i == 0 }"
            >{{ item.title }}</a
          >
        </div>

        <div class="row">
          <div class="col-md-6 col-sm-12 col-xs-12">
            <!-- 焦点图 -->
            <el-carousel :interval="5000" arrow="always">
              <el-carousel-item
                v-for="(item, i) in newsImgsList"
                :key="item.id"
              >
                <a
                  target="_blank"
                  @click="goto('newsdetails?id=' + item.id)"
                  :title="item.title"
                  ><img :src="ImageUrl + item.firstImg"
                /></a>
                <span class="shadow"
                  ><a
                    target="_blank"
                    :text="item.title"
                    @click="goto('newsdetails?id=' + item.id)"
                    >{{ item.title }}</a
                  ></span
                >
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="col-md-6 col-sm-12 col-xs-12">
            <ul class="news-ul">
              <li v-for="item in newsList" :key="item.id">
                <a
                  @click="goto('newsdetails?id=' + item.id)"
                  :title="item.title"
                >
                  <div class="news-tit">
                    {{ item.title }}
                  </div>
                  <div class="news-time">
                    <img src="@/images/icon-date.png" alt="" /><span>{{
                      filterTime(item.releasetime)
                    }}</span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- newsParty -->
    <div class="caseList wow fadeInUp">
      <div class="container-content">
        <div class="title-box">
          <div class="title">
            <span>慈善项目</span>
          </div>
        </div>
        <div class="swiper-container caseList-swiper">
          <swiper-project :imgData="ImgsList"></swiper-project>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="@/css/index.css"></style>
<!-- caseList -->
<style src="@/css/swiper.min.css" />
<style src="@/css/swiper-news.css" />

<script>
import moment from "moment";
import SwiperComponent from "@/components/swiper_banner";
import SwiperProject from "@/components/swiper_project";
export default {
  name: "index",
  components: { SwiperComponent, SwiperProject },
  data() {
    return {
      ImageUrl: this.$ImageUrl,
      bannerList: [],
      AboutUs: "",
      classifyList: [],
      newsImgsList: [],
      newsList: [],
      ImgsList: [],
    };
  },

  mounted() {
    this.getBannerList();
    this.getAboutUs();
    this.getclassifyList();
    this.getnewsList();
    this.getimgList();
    // this.SwiperJS();
  },
  methods: {
    getBannerList() {
      this.$http.get("/api/Banner/list").then((resJson) => {
        if (resJson.data.code == 200) {
          this.bannerList = resJson.data.data.result;
        }
      });
    },
    getAboutUs() {
      this.$http.get("/api/Introduction/505443866734661").then((resJson) => {
        if (resJson.data.code == 200) {
          var text = resJson.data.data.content.replace(/<[^>]*>/g, "");
          if (text.length > 180) {
            text = text.substring(0, 180) + "...";
          }
          this.AboutUs = text;
        }
      });
    },
    getclassifyList() {
      this.$http.get("/api/Classify/list/505775402811461").then((resJson) => {
        if (resJson.data.code == 200) {
          this.classifyList = resJson.data.data;
        }
      });
    },
    getnewsList() {
      this.$http.get("/api/News/index/505775402811461").then((resJson) => {
        if (resJson.data.code == 200) {
          this.newsImgsList = resJson.data.data.imgList;
          this.newsList = resJson.data.data.newsList;
        }
      });
    },
    getimgList() {
      this.$http.get("/api/News/indeximage/505789928968261").then((resJson) => {
        if (resJson.data.code == 200) {
          this.ImgsList = resJson.data.data.imgList;
        }
      });
    },
    filterTime(time) {
      if (time) return moment(time).format("YYYY.MM.DD");
    },
    goto(data) {
      this.$router.push(data);
    },
  },
};
</script>
<style>
.row .el-carousel {
  height: 400px;
}
.row .el-carousel__item {
  height: 400px;
  display: flex;
  flex-direction: column;
}
.row .el-carousel__item img {
  width: 100%;
  height: 400px;
}
.swiper-container .el-carousel__arrow {
  display: none;
}
.row .el-carousel__indicators--horizontal {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: none;
}
.row .el-carousel__arrow {
  border-radius: 0%;
  top: 63%;
}
.row .el-carousel__item .D1fBt {
  overflow: hidden;
  zoom: 1;
  height: 16px;
  z-index: 10;
}
.row .el-carousel__item .shadow {
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 10;
  height: 44px;
  line-height: 44px;
  display: block;
  background: rgba(0, 0, 0, 0.6);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr='#80000000', endColorstr='#80000000')\;6A;
}

.row .el-carousel__item .shadow a {
  text-decoration: none;
  color: #515151;
  font-size: 15px;
  padding-left: 10px;
  width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 40px;
  color: #fff;
}
</style>
